import { Injectable } from '@angular/core';
import { FindDataClass } from '../find.data.class';
import { FindResponseInterface } from '../../../interfaces/response/find.response.interface';
import { CountsInterface } from '../../../interfaces/model/counts.interface';
import { CountsService } from '../../counts.service';

@Injectable({
  providedIn: 'root'
})
export class CountsDataService extends FindDataClass<CountsInterface> {

  constructor(private countsService: CountsService) {
    super();
  }

  public findCall(): Promise<FindResponseInterface<CountsInterface>> {
    return this.retryFindCall();
  }

  private retryFindCall(retries = 3, err?: Error): Promise<FindResponseInterface<CountsInterface>> {
    if (! retries) {
      return Promise.reject(err);
    }

    return this.countsService.find()
      .catch(err => this.retryFindCall((retries - 1), err));
  }

}
