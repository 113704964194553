import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FindResponseInterface } from '../interfaces/response/find.response.interface';
import { CountsInterface } from '../interfaces/model/counts.interface';

@Injectable({
  providedIn: 'root'
})
export class CountsService {

  constructor(private api: ApiService) {
    // Noop
  }

  public find(): Promise<FindResponseInterface<CountsInterface>> {
    return this.api.get('/counts');
  }

}
